import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../style/theme'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Copy from '../assets/copyIcon.svg'
const INPUT_RADIUS = '6px'

const TextInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;

  input {
    border-radius: ${INPUT_RADIUS};
    outline: none;
    line-height: 2rem;
    font-size: ${props => props.theme.defaultFontSize};
    padding: 4px 8px;
    margin-right: 6px;
    background: transparent;
    border: 2px solid ${props => props.theme.defaultInputBackgroundColor};
    transition: background-color 0.2s ease-in-out;
    background-color: ${props => props.theme.defaultInputBackgroundColor};
    &:focus {
      border-color: ${props => props.theme.focusColor};
    }
  }

  label {
    margin-bottom: 0.5rem;
  }

  ${({ copyMode }) =>
    copyMode &&
    css`
      input {
        padding-right: 2rem;
      }
    `}
`

const CopyButton = styled.img`
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
`

export const Error = styled.span`
  position: absolute;
  left: 0;
  top: 103%;
  font-size: 0.75rem;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.errorColor};
`

export const TextInput = ({
  onChange,
  onBlur,
  onFocus,
  placeholder,
  label,
  type = 'text',
  name,
  value = '',
  error,
  style,
  readOnly = false,
  copyMode = false,
}) => {
  const [showCopy, handleShowCopy] = React.useState(false)
  return (
    <TextInputContainer style={style} copyMode={copyMode}>
      {label ? <label>{label}</label> : null}

      <input
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        readOnly={readOnly}
        style={Boolean(error) ? { borderColor: theme.errorColor } : {}}
      />
      {error ? <Error>{error}</Error> : null}
      {copyMode && (
        <CopyToClipboard text={value}>
          <CopyButton src={Copy} onClick={() => handleShowCopy(true)} />
        </CopyToClipboard>
      )}
      {showCopy && (
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            fontSize: '.8rem',
            right: 0,
            textAlign: 'Right',
            color: '#379945',
          }}
        >
          Copied!
        </div>
      )}
    </TextInputContainer>
  )
}
