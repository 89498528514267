import React from 'react'
import { PageWrapper } from '../components'
import { Tile, Button, InfoPanel } from '../components'
import styled from 'styled-components'
import { ReactComponent as SameYou } from '../assets/sameYouLogo.svg'
import InstagramEmbed from 'react-instagram-embed'
import { media } from '../style/theme'
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  svg {
    height: 70px;
  }

  ${media.forSmallOnly`
    flex-direction: column;
    margin-bottom: 0;

    svg {
      margin-bottom: 1rem;
      height: 50px;
    }
  `}
`

const MainBody = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  > div {
    width: 50%;
    padding: 1rem;
  }

  ${media.forSmallOnly`
    flex-direction: column;

    > div {
      width: 100%;
      padding: 0;

      iframe {
        min-width: 300px !important;
      }
    }
  `}
`

export const DonateLink = ({ children }) => {
  return (
    <a
      href="https://fundly.com/guess-the-throne"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  )
}

export const DonatePage = () => {
  return (
    <PageWrapper>
      <Tile>
        <HeaderContainer>
          <SameYou />
          <DonateLink>
            <Button medium>Donate</Button>
          </DonateLink>
        </HeaderContainer>
        <InfoPanel
          noIcon
          style={{
            backgroundColor: '#fbebd8',
            fontSize: '1.2rem',
            color: '#333333',
            textAlign: 'center',
          }}
        >
          Have you enjoyed using Guess the Throne? Please consider donating to
          help support the operating costs of the website. 50% of all donations
          will go to support Emilia Clarke's SameYou charity.
        </InfoPanel>

        <MainBody>
          <div>
            <p>
              Thank you for joining us on GuessTheThrone.com, a wild idea which
              exploded in just a few weeks due to the tremendous support of this
              fandom. We never expected to receive the amount of traffic we
              have. It has been quite the ride.
            </p>
            <p>
              It has taken a lot of nights and weekends, which we've been glad
              to give. However, it has also meant significant development and
              operating costs. We always planned to keep Guess The Throne free,
              and it will remain so, but let us ask this: If you've enjoyed your
              death pool, please consider a donation of $5 to our{' '}
              <DonateLink>Fundly</DonateLink>. We'll donate half of each
              contribution to Emilia Clarke's charity, SameYou, which aims to
              help young adults become themselves again after brain injury and
              stroke. We didn't start Guess The Throne for profit, so all
              donations above our operating costs will be given entirely to
              SameYou.
            </p>
            <br />
            <p>Thank you, and happy watching.</p>
            <p>Marcus</p>
          </div>
          <InstagramEmbed
            url="https://www.instagram.com/p/BvRr-IKlQCm/"
            hideCaption
            containerTagName="div"
          />
        </MainBody>
      </Tile>
    </PageWrapper>
  )
}
