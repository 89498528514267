import { CHARACTERS } from './const'
import firebase from '../config/firebase'

const db = firebase.firestore()

export const getUserEntry = (groupDetail, userId) => {
  return (
    groupDetail.entries &&
    groupDetail.entries.find(entry => userId === entry.createdBy)
  )
}

export const getCharacterByIdForSelect = id => {
  if (!id) {
    return
  }
  const character = CHARACTERS.find(c => c.id === id)

  if (!character) {
    return
  }

  return {
    label: character.name,
    value: character.id,
  }
}

export const getRandomItemFromArray = items =>
  items[Math.floor(Math.random() * items.length)]

export const addScoreToEntries = (entries, scores) => {
  return entries.map(entry => ({
    ...entry,
    ...scores[entry.id],
  }))
}

export const getAllEntriesDetail = async user => {
  const entryRequests = user.entries.map(entry =>
    db
      .collection('entries')
      .doc(entry.id)
      .get()
  )

  const requests = await Promise.all(entryRequests)
  return requests.reduce((accu, item) => {
    const entry = item.data()
    if (entry) {
      entry.id = item.id
      accu.push(entry)
    }

    return accu
  }, [])
}
