import React from 'react'
import {
  PageWrapper,
  Loading,
  Button,
  InfoPanel,
  FancyGraphic,
  TextInput,
  Table,
  ShareIcons,
} from '../components'
import firebase from '../config/firebase'
import { Link } from 'react-router-dom'
import { getUserEntry, addScoreToEntries } from '../utils/utilityService'
import styled from 'styled-components'
import { media } from '../style/theme'
import Rodal from 'rodal'
import { Helmet } from 'react-helmet'
import dlv from 'dlv'
import { IsFromProductHuntConsumer } from '../context'
import { LOCK_MODE } from '../utils/const'

const LoggedInUsersCell = row => {
  const isLoggedInUserEntry =
    row.original.createdBy === row.column.userEntry.userId
  const isNameColumn = row.column.id === 'name'
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {isLoggedInUserEntry && isNameColumn && (
        <span role="img" aria-label="party hat">
          🎉
        </span>
      )}
      {isNameColumn ? (
        isLoggedInUserEntry || LOCK_MODE ? (
          <Link to={`/groups/${row.column.groupDetail.id}/${row.original.id}`}>
            {row.value}
          </Link>
        ) : (
          <span>{row.value}</span>
        )
      ) : (
        row.value
      )}
    </div>
  )
}

const createTableData = (groupDetail, userEntry) => {
  const columns = [
    {
      Header: 'Rank',
      accessor: 'rank',
      userEntry,
      groupDetail,
      Cell: LoggedInUsersCell,
    },
    {
      Header: 'Entry Name',
      accessor: 'name',
      userEntry,
      groupDetail,
      Cell: LoggedInUsersCell,
      minWidth: 200,
    },
    {
      Header: 'Points',
      accessor: 'totalScore',
      userEntry,
      groupDetail,
      Cell: LoggedInUsersCell,
    },
    {
      Header: 'Max',
      accessor: 'maxScore',
      userEntry,
      groupDetail,
      Cell: LoggedInUsersCell,
    },
  ]
  const data = groupDetail.entries

  return { columns, data }
}

const EntryTable = props => {
  if (!props.groupDetail.entries.length) {
    return (
      <span>
        No entries yet!{' '}
        {!LOCK_MODE && (
          <Link to={`/groups/${props.groupDetail.id}/create-entry`}>
            Create one?
          </Link>
        )}
      </span>
    )
  }

  const { columns, data } = createTableData(props.groupDetail, props.user)

  return <Table columns={columns} data={data} {...props} />
}

const HeaderContainer = styled.div`
  position: relative;

  .invite-friends-button {
    align-self: flex-start;
    position: absolute;
    top: 0;
    right: 0;
  }

  .invite-friends-rodal-modal {
    .rodal-dialog {
      height: 650px !important;
      width: 500px !important;
    }
  }

  ${media.forSmallOnly`
    flex-direction: column;

    .invite-friends-button {
      align-self: auto;
      position: relative;
    }

    .invite-friends-rodal-modal {
      .rodal-dialog {
        height: 600px !important;
        width: 90% !important;
      }
    }
  `}
`

const db = firebase.firestore()

export class GroupDetailPage extends React.Component {
  state = {
    groupDetail: null,
    loading: true,
    isModalVisible: dlv(this, 'props.location.state.fromCreateEntry', false),
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    const groupDetailRequest = await db
      .collection('groups')
      .doc(params.groupId)
      .get()
    const scoringInformationRequest = await db
      .collection('scores')
      .doc(params.groupId)
      .get()

    const groupDetail = groupDetailRequest.data()

    if (groupDetail.entries.length === 0) {
      return this.setState({
        groupDetail,
        loading: false,
      })
    }

    const scoringInformation = scoringInformationRequest.data().entries
    groupDetail.id = groupDetailRequest.id
    groupDetail.entries = addScoreToEntries(
      groupDetail.entries,
      scoringInformation
    )

    this.setState({
      groupDetail,
      loading: false,
    })
  }

  render() {
    const { loading, groupDetail, isModalVisible } = this.state
    const { user } = this.props

    if (loading || !user) {
      return (
        <PageWrapper>
          <Loading />
        </PageWrapper>
      )
    }

    if (groupDetail.entries.length === 0) {
      return (
        <PageWrapper tiled>
          <h1>{groupDetail.name} Group</h1>
          <p>{groupDetail.entries.length} members</p>
          {groupDetail.description ? <p>{groupDetail.description}</p> : null}
          <p>Since your group does not have any entries it cannot be scored.</p>
        </PageWrapper>
      )
    }

    const userEntry = getUserEntry(groupDetail, user.userId)

    return (
      <PageWrapper tiled>
        <Helmet>
          <title>{groupDetail.name} | Guess the Throne</title>
        </Helmet>
        <HeaderContainer>
          <div>
            <h1>{groupDetail.name} Group</h1>
            <p>{groupDetail.entries.length} members</p>
            {groupDetail.description ? <p>{groupDetail.description}</p> : null}
          </div>
          {!LOCK_MODE && (
            <>
              {userEntry ? (
                <Button
                  className="invite-friends-button"
                  medium
                  onClick={() => this.setState({ isModalVisible: true })}
                >
                  Invite Friends
                </Button>
              ) : (
                <Link
                  className="invite-friends-button"
                  to={`/groups/${groupDetail.id}/create-entry`}
                >
                  <Button medium>Create Your Entry</Button>
                </Link>
              )}
            </>
          )}

          <Rodal
            visible={isModalVisible}
            className="invite-friends-rodal-modal"
            onClose={() => this.setState({ isModalVisible: false })}
          >
            <IsFromProductHuntConsumer>
              {({ isFromProductHunt }) => {
                return (
                  <>
                    <h2>Invite Friends to Join</h2>
                    <p style={{ margin: 0 }}>
                      Share the link on social media or directly with your
                      friends to invite them to join.
                    </p>
                    <FancyGraphic style={{ margin: '0 auto' }} />
                    {isFromProductHunt ? (
                      <InfoPanel>
                        <span role="img" aria-label="hand wave">
                          👋🏻
                        </span>
                        Hey product hunter, thank you trying us out. Please
                        leave us any feedback you have on our{' '}
                        <a
                          href="https://www.producthunt.com/posts/guess-the-throne"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Product Hunt
                        </a>{' '}
                        page!
                      </InfoPanel>
                    ) : null}
                    {groupDetail.isUnlisted && !isFromProductHunt && (
                      <InfoPanel>
                        Anyone with the link can join your group and see
                        everyone's entries so be careful where you share this!
                      </InfoPanel>
                    )}

                    <TextInput
                      value={`https://guessthethrone.com/groups/${
                        groupDetail.id
                      }`}
                      readOnly
                      copyMode
                    />
                    <ShareIcons
                      url={`https://guessthethrone.com/groups/${
                        groupDetail.id
                      }`}
                    />
                  </>
                )
              }}
            </IsFromProductHuntConsumer>
          </Rodal>
        </HeaderContainer>
        {groupDetail.isFeatured && (
          <div style={{ paddingBottom: '1rem' }}>
            Check out our new{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${groupDetail.featureLink}?ref=guessthethrone`}
            >
              podcast
            </a>
            !
          </div>
        )}
        {!LOCK_MODE && (
          <InfoPanel>
            Groups and entries lock <b>April 14, 2019</b>, before the start of
            Episode 1. At that point, you can look at other people's entries.
          </InfoPanel>
        )}

        <h2>Entries</h2>
        <EntryTable
          {...this.props}
          groupDetail={groupDetail}
          userEntry={userEntry}
          defaultSorted={[
            {
              id: 'rank',
              desc: false,
            },
          ]}
        />
      </PageWrapper>
    )
  }
}
