import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

// Right wall
import TheWall1 from '../assets/theWall/gtt-thewall-1.svg'
// Left wall
import TheWall2 from '../assets/theWall/gtt-thewall-2.svg'
// Dragon
import TheWall3 from '../assets/theWall/gtt-thewall-3.svg'
// Castle
import TheWall4 from '../assets/theWall/gtt-thewall-4.svg'
// Rocks
import TheWall5 from '../assets/theWall/gtt-thewall-5.svg'

const GRAPHIC_SIZE = '30ch'
const SIZE = '300px'

const FancyGraphicContainer = styled.div`
  width: ${SIZE};
  height: ${SIZE};
  margin: 0 auto 2rem;
  position: relative;

  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    position: absolute;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
  }

  .card1 {
    width: ${GRAPHIC_SIZE};
    height: ${GRAPHIC_SIZE};
    background-image: url(${TheWall1});
  }

  .card2 {
    width: ${GRAPHIC_SIZE};
    height: ${GRAPHIC_SIZE};
    background-image: url(${TheWall2});
  }

  .card3 {
    width: ${GRAPHIC_SIZE};
    height: ${GRAPHIC_SIZE};
    background-image: url(${TheWall3});
  }

  .card4 {
    width: ${GRAPHIC_SIZE};
    height: ${GRAPHIC_SIZE};
    background-image: url(${TheWall4});
  }

  .card5 {
    width: ${GRAPHIC_SIZE};
    height: ${GRAPHIC_SIZE};
    background-image: url(${TheWall5});
  }
`

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`
const trans2 = (x, y) => `translate3d(${x / 11}px,${y / 15}px,0)`
const trans3 = (x, y) => `translate3d(${x / 4 - 40}px,${y / 4}px,0)`
const trans4 = (x, y) => `translate3d(${x / 13}px,${y / 13}px,0)`
const trans5 = (x, y) => `translate3d(${x / 8}px,${y / 10}px,0)`

export const FancyGraphic = ({ style = {} }) => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))
  return (
    <FancyGraphicContainer
      className="container"
      style={style}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        className="card1"
        style={{ transform: props.xy.interpolate(trans1) }}
      />
      <animated.div
        className="card2"
        style={{ transform: props.xy.interpolate(trans2) }}
      />

      <animated.div
        className="card4"
        style={{ transform: props.xy.interpolate(trans4) }}
      />
      <animated.div
        className="card5"
        style={{ transform: props.xy.interpolate(trans5) }}
      />
      {/* Dragon last so it's over the rocks */}
      <animated.div
        className="card3"
        style={{ transform: props.xy.interpolate(trans3) }}
      />
    </FancyGraphicContainer>
  )
}
