import React from 'react'
import { DEMO_CHARACTERS, DEMO_STUB_ENTRY } from '../utils/const'
import styled from 'styled-components'
import { LiveOrDieComponent } from '../pages/CreateEntry'
import { ViewTable } from '../pages/ViewEntry'
import { media } from '../style/theme'

// Sets up data for the form
const characters = {}
DEMO_CHARACTERS.forEach(c => (characters[c.id] = false))

const BracketDemoContainer = styled.div`
  margin: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
`

const HowItWorksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  align-items: center;
  margin-bottom: 1.5rem;

  > div {
    flex: 1;
    &:first-child {
      margin: ${props => (props.reverse ? '0 0 0 2rem' : '0 2rem 0 0')};
    }
  }

  ${media.forSmallMediumOnly`
      flex-direction: column;

      > div {
        flex: 1;
        width: 100%;

        &:first-child {
          margin: 0;
        }
      }
  `}
`

export class HowItWorksSection extends React.Component {
  state = {
    characters,
  }

  render() {
    return (
      <>
        <HowItWorksBlock>
          <div>
            <h2>Filling out your death pool</h2>
            <p>
              Go through the list of living characters and decide their fate.
            </p>
          </div>
          <BracketDemoContainer>
            <LiveOrDieComponent
              switchCharacters={DEMO_CHARACTERS}
              stateCharacters={this.state.characters}
              onChange={({ id }) => {
                const newCharacters = { ...this.state.characters }
                newCharacters[id] = !this.state.characters[id]

                this.setState({ characters: newCharacters })
              }}
            />
          </BracketDemoContainer>
        </HowItWorksBlock>
        <HowItWorksBlock reverse={true}>
          <div>
            <h2>Scoring</h2>
            <p>
              Each correct guess yields one point. Scores will be updated after
              each episode. The player with the most points wins!
            </p>
          </div>
          <BracketDemoContainer>
            <ViewTable characters={DEMO_STUB_ENTRY} />
          </BracketDemoContainer>
        </HowItWorksBlock>
      </>
    )
  }
}
