import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../style/theme'

const FancyButton = styled.button`
  cursor: pointer;
  background-color: ${props => props.theme.primaryColor};
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.5);
  font-size: ${props => (props.medium ? '1rem' : '1.25rem')};
  color: ${props => props.theme.white};
  letter-spacing: 0;
  text-align: center;
  border-radius: 40px;
  padding: ${props => (props.medium ? '0.75rem 2.5rem' : '0.75rem 4.5rem')};
  text-transform: uppercase;
  transition: ${props => props.theme.defaultTransition};
  position: relative;
  margin-bottom: 1rem;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${theme.disabledColor};
    `}

  ${media.forSmallOnly`
    font-size: 1rem;
    width: 100%;
  `}

  &:hover {
    transform: scale(1.02);
  }
`

export const Button = ({
  children,
  type = 'button',
  onClick,
  style,
  disabled = false,
  className = '',
  medium = false,
}) => {
  return (
    <FancyButton
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={className}
      medium={medium}
    >
      {children}
    </FancyButton>
  )
}
