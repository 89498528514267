import React from 'react'
import { PageWrapper } from '../components'
import { Tile } from '../components'

const SupportLink = () => (
  <a href="mailto:support@itsadate.app">support@itsadate.app</a>
)

export const HowItWorksPage = () => {
  return (
    <PageWrapper>
      <Tile>
        <h2>Frequently Asked Questions</h2>
        <h3>Is this free to use?</h3>
        <p>Yes, 100%.</p>
        <h3>Who Wins?</h3>
        <p>
          Each correct guess yields one point. We will update scores after each
          episode. Correct guesses are worth one point and bonus question
          guesses are worth three.
        </p>
        <h3>When do entries update?</h3>
        <p>We will post the updated scores after each episode.</p>
        <h3>What does the winner win?</h3>
        <p>
          Featured and private bracket pools are both competing for a sense of
          pride and accomplishment. However, feel free to up the stakes with
          your friends!
        </p>
        <h3>Can I submit the same death pool (entry) to multiple groups?</h3>
        <p>No, but you can submit identical entries to each group.</p>
        <h3>What if someone joins the army of the dead?</h3>
        <p>
          They're dead{' '}
          <span role="img" aria-label="skull">
            💀
          </span>
          .
        </p>
        <h3>Does the Night King count as dead or alive?</h3>
        <p>
          Our definition of "dead" is the character can be killed. So the Night
          King is alive, even though he commands The Army of the Dead.
        </p>
        <h3>What if we don't know for sure if someone has died?</h3>
        <p>
          If we don't see it happen on the screen, then we're counting them as
          alive. For example, when Brienne of Tarth left The Hound for dead, he
          would of been scored as alive.
        </p>
        <h3>What if someone comes back to life?</h3>
        <p>
          They will be considered dead until they are brought back to life. Once
          brought back to life, they will be considered alive. Their final state
          at the end of the season (dead or alive) is how the character will be
          scored.
        </p>
        <h3>
          What if something weird happens - like two characters are the same
          people?
        </h3>
        <p>
          We have a small council of Game of Thrones scholars that will have
          final say on all judgements.
        </p>
      </Tile>
      <Tile>
        <h2>Groups</h2>
        <h3>What's the different between public and unlisted?</h3>
        <p>
          Public groups can be found by users on our website or joined by the
          link. Unlisted groups can only be joined by the share link.
        </p>
        <h3>Can we remove users from our group?</h3>
        <p>
          No, but if your group is unlisted only people with the link can join
          it. If you run into a problem user reach out to us at <SupportLink />{' '}
          and we can take care of it.
        </p>
      </Tile>
      <Tile>
        <h2>Miscellaneous</h2>
        <h3>What's It's a Date?</h3>
        <p>
          It's a Date is an experience-based dating app founded in Washington
          D.C. <a href="https://www.itsadate.app">Check us out</a>! (shameless
          plug)
        </p>
        <h3>Why did you all build this?</h3>
        <p>
          We're diehard fans with enough coding knowledge to make a fun idea
          happen. See if you can beat the It's a Date team with your own
          bracket!
        </p>
        <h3>
          I'd like for my group to be featured, how do I make that happen?
        </h3>
        <p>
          Email us at <SupportLink /> with your group title and a little about
          yourself! We'd love to speak with you.
        </p>
        <h3>
          I'd like to write about you or feature you in a blog. How can I do
          that?
        </h3>
        <p>
          Shoot us an email at <SupportLink />.
        </p>
        <h3>Can you open source the code?</h3>
        <p>Yes! It will be open sourced after the season concludes.</p>
      </Tile>
    </PageWrapper>
  )
}
