import React from 'react'
import ReactSwitch from 'react-switch'
import styled from 'styled-components'
import { media } from '../style/index'

const PrivatePublicSwitch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }
  }

  ${media.forSmallOnly`
    span {
      font-size: .8rem;

      &:first-child {
        margin-right: .5rem;
      }

      &:last-child {
        margin-left: .5rem;
      }
    }
  `}
`

const SwitchContainer = styled.div`
  > p {
    text-align: center;
    font-size: 0.75rem;
  }

  ${media.forSmallOnly`
    > p {
      font-size: 0.6rem;
    }
  `}
`

export const Switch = ({
  leftText,
  rightText,
  onChange,
  checked,
  style,
  onColor = '#888888',
}) => {
  return (
    <SwitchContainer style={style}>
      <PrivatePublicSwitch>
        <span>{leftText}</span>
        <label>
          <ReactSwitch
            onChange={onChange}
            checked={checked}
            height={35}
            width={70}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={onColor}
          />
        </label>
        <span>{rightText}</span>
      </PrivatePublicSwitch>
    </SwitchContainer>
  )
}
