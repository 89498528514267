import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

let config = {
  apiKey: 'AIzaSyCKu_Yd5jaPNCh4GIa0wjUmZ6NzGsoL6FE',
  authDomain: 'guess-the-throne-dev.firebaseapp.com',
  databaseURL: 'https://guess-the-throne-dev.firebaseio.com',
  projectId: 'guess-the-throne-dev',
  storageBucket: 'guess-the-throne-dev.appspot.com',
  messagingSenderId: '801024471429',
}

if (
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_RUN_AGAINST_PROD
) {
  config = {
    apiKey: 'AIzaSyA4DCxpY6cI6VobpYTEPSIp0_Orr3Oa3nQ',
    authDomain: 'guess-the-throne.firebaseapp.com',
    databaseURL: 'https://guess-the-throne.firebaseio.com',
    projectId: 'guess-the-throne',
    storageBucket: 'guess-the-throne.appspot.com',
    messagingSenderId: '75669815022',
  }
}

firebase.initializeApp(config)
export default firebase
