import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
} from 'react-share'

const ShareIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    margin: 0 5px;
    cursor: pointer;
  }
`

export const ShareIcons = ({ url }) => {
  return (
    <ShareIconsContainer>
      <FacebookShareButton
        url={url}
        quote="I just created a Game of Thrones death pool on Guess the Throne. Join my group!"
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <LinkedinShareButton
        url={url}
        title="I just created a Game of Thrones death pool on Guess the Throne. Join my group!"
        description={`I just created a death pool on Guess the Throne! Join my group by clicking this link: ${url}`}
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>
      <TwitterShareButton
        url={url}
        title="I just created a Game of Thrones death pool on Guess the Throne. Join my group!"
        hashtags={['guessthethrone', 'gameofthrones', 'got']}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <RedditShareButton
        url={url}
        title="Guess the Throne | The Game of Thrones Death Pool"
      >
        <RedditIcon size={32} round={true} />
      </RedditShareButton>
      <EmailShareButton
        url={url}
        subject="Join my Game of Thrones Death Pool Group"
        body={`I just created a death pool on Guess the Throne! Join my group by clicking this link: ${url}`}
      >
        <EmailIcon size={32} round={true} />
      </EmailShareButton>
    </ShareIconsContainer>
  )
}
