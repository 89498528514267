import React from 'react'
import { PageWrapper, Tile } from '../components'
import firebase from '../config/firebase'
import { media, theme } from '../style/theme'
import { Loading, Button } from '../components'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { CharacterImageName } from './CreateEntry'
import { FormGroupSelect, SELECT_OPTIONS } from './CreateEntry'
import {
  CHARACTERS,
  FIRST_DIE_QUESTION,
  LAST_DIE_QUESTION,
  WIN_THRONE_QUESTION,
} from '../utils/const'
import { getCharacterByIdForSelect } from '../utils/utilityService'
import {
  WHO_WINS_THE_THRONE,
  WHO_DIES_FIRST,
  WHO_DIES_LAST,
} from '../utils/scoring'
import { LOCK_MODE } from '../utils/const'
import { EntriesConsumer, AnalyticsConsumer } from '../context'

export const relateEntryToFixturesData = entry => {
  entry.charactersWithDetails = CHARACTERS.map(char => ({
    ...char,
    isDeadAtTheEnd: entry.characters[char.id],
  }))

  return entry
}

const ViewEntryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const GridHeader = styled.div`
  background-color: ${props => props.theme.primaryColor};
  ${props => props.theme.tableHeaderStyles}
  display: flex;
  justify-content: space-around;
  align-items: center;

  > div {
    color: ${props => props.theme.white};
  }
`

const GridItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
  padding: 1rem 2rem;

  > div {
    flex: 1;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }

  &:last-child {
    border: none;
  }

  ${media.forSmallOnly`
    padding: 1rem 0.35rem 1rem;

    > p {
     font-size: 1rem;
    }
  `}
`
const SIZE = '35px'
const SharedGuessStyles = css`
  width: ${SIZE};
  height: ${SIZE};
  line-height: ${SIZE};
  color: ${props => props.theme.white};
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
`

const CorrectGuess = styled.div`
  background-color: #51ca7a;
  ${SharedGuessStyles}
`

const IncorrectGuess = styled.div`
  background-color: #d0021b;
  ${SharedGuessStyles}
`

const NullGuess = styled.div`
  height: ${SIZE};
`

const db = firebase.firestore()

const renderCorrectVersusIncorrect = character => {
  switch (character.isCorrect) {
    case true:
      return <CorrectGuess>+1</CorrectGuess>
    case false:
      return <IncorrectGuess>X</IncorrectGuess>
    default:
      return <NullGuess>-</NullGuess>
  }
}

export const ViewTable = ({ characters, characterFates, showEnded }) => {
  const gradedCharacters = characters.map(c => ({
    ...c,
    /**
     * If the show ended, show the view like you always would
     * If the show hasn't only score dead people
     */
    isCorrect: showEnded
      ? c.isDeadAtTheEnd === characterFates[c.id]
      : characterFates[c.id]
      ? c.isDeadAtTheEnd === characterFates[c.id]
      : null,
  }))
  return (
    <>
      <GridHeader>
        <div>Character</div>
        <div>Guess</div>
        <div>Outcome</div>
      </GridHeader>
      {gradedCharacters.map(character => {
        return (
          <GridItem key={character.id}>
            <CharacterImageName character={character} />
            <div>{character.isDeadAtTheEnd ? 'DIES' : 'LIVES'}</div>
            <div>{renderCorrectVersusIncorrect(character)}</div>
          </GridItem>
        )
      })}
    </>
  )
}

const ViewBonusQuestions = styled.div``

const isBonusQuestionCorrect = (bonusQuestionAnswers, id, answer) =>
  bonusQuestionAnswers[id]
    ? bonusQuestionAnswers[id] === answer
      ? theme.positiveColor
      : theme.negativeColor
    : null

export class ViewEntryPageComponent extends React.Component {
  state = { entry: null }
  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    const entrySnap = await db
      .collection('entries')
      .doc(params.entryId)
      .get()
    const entryData = entrySnap.data()
    entryData.id = entrySnap.id
    const entry = relateEntryToFixturesData(entryData)

    this.setState({ entry })
  }

  render() {
    const { entry } = this.state
    const { characterFates, showEnded, bonusQuestionAnswers } = this.props

    if (!entry) {
      return <Loading />
    }

    const canEdit = this.props.user.userId === entry.createdBy
    const scoresForEntry = this.props.scores[entry.id]

    return (
      <PageWrapper>
        <Tile>
          <ViewEntryHeader>
            <div>
              <h1>{entry.name}</h1>
              <p>{entry.associatedGroupName}</p>
            </div>
            <div>
              {canEdit && !LOCK_MODE && (
                <Link to={`/groups/${entry.associatedGroup}/${entry.id}/edit`}>
                  <Button>Edit</Button>
                </Link>
              )}
              {LOCK_MODE && scoresForEntry && (
                <>
                  <p>Rank: {scoresForEntry.rank}</p>
                  <p>Score: {scoresForEntry.totalScore}</p>
                  <p>Max: {scoresForEntry.maxScore}</p>
                </>
              )}
            </div>
          </ViewEntryHeader>
          <ViewTable
            characters={entry.charactersWithDetails}
            characterFates={characterFates}
            showEnded={showEnded}
          />
        </Tile>
        <Tile>
          <h2>Bonus Questions</h2>
          <ViewBonusQuestions>
            <FormGroupSelect
              label={FIRST_DIE_QUESTION}
              borderColor={isBonusQuestionCorrect(
                bonusQuestionAnswers,
                'whoDiesFirst',
                entry.whoDiesFirst
              )}
              score={WHO_DIES_FIRST}
              name="whoDiesFirst"
              options={SELECT_OPTIONS}
              isDisabled
              value={getCharacterByIdForSelect(entry.whoDiesFirst)}
            />
            <FormGroupSelect
              label={LAST_DIE_QUESTION}
              borderColor={isBonusQuestionCorrect(
                bonusQuestionAnswers,
                'whoDiesLast',
                entry.whoDiesLast
              )}
              score={WHO_DIES_LAST}
              name="whoDiesLast"
              options={SELECT_OPTIONS}
              isDisabled
              value={getCharacterByIdForSelect(entry.whoDiesLast)}
            />
            <FormGroupSelect
              label={WIN_THRONE_QUESTION}
              borderColor={isBonusQuestionCorrect(
                bonusQuestionAnswers,
                'winsIronThrone',
                entry.winsIronThrone
              )}
              score={WHO_WINS_THE_THRONE}
              name="winsIronThrone"
              options={SELECT_OPTIONS}
              isDisabled
              value={getCharacterByIdForSelect(entry.winsIronThrone)}
            />
          </ViewBonusQuestions>
        </Tile>
      </PageWrapper>
    )
  }
}

export const ViewEntryPage = props => (
  <EntriesConsumer>
    {({ scores }) => (
      <AnalyticsConsumer>
        {({ characterFates, showEnded, bonusQuestionAnswers }) => (
          <ViewEntryPageComponent
            {...props}
            scores={scores}
            characterFates={characterFates}
            showEnded={showEnded}
            bonusQuestionAnswers={bonusQuestionAnswers}
          />
        )}
      </AnalyticsConsumer>
    )}
  </EntriesConsumer>
)
