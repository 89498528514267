import React from 'react'
import { PageWrapper, Loading, Table, Button, Tile } from '../components'
import firebase from '../config/firebase'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../style/theme'
import BarstoolFeature from '../assets/barstoolFeature.png'
import TowerOfBabble from '../assets/towerOfBabbleFeature.png'
import { LOCK_MODE } from '../utils/const'
import { EntriesConsumer, AnalyticsConsumer } from '../context'
import PodcastWinterfell from '../assets/podcastWinterfell.png'
import DeathTracker from '../assets/deathTracker.png'

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.forSmallOnly`
    flex-direction: column;

    button {
      align-self: auto;
    }
  `}
`

export const FeaturedTiles = ({ analytics }) => {
  return (
    <FeaturedTileRow>
      <DeathTrackerTile>
        <img src={DeathTracker} alt="Three eyed raven white walkers" />
        <h2>Death Tracker</h2>
        <div>
          <p>
            {analytics.perfectEntriesRemaining.toLocaleString()} Perfect Entries
            Remain!
          </p>
          <span>
            That is {Math.round(analytics.percentPerfect * 100)}% of the{' '}
            {analytics.totalEntries.toLocaleString()} entries created. Check out
            the analytics page to see how your picks compared to everyone elses.
          </span>
        </div>
        <Link to="/analytics">
          <Button medium>View Analytics</Button>
        </Link>
      </DeathTrackerTile>
      <Tile>
        <a
          href="https://podcastwinterfell.com/?ref=guessthethrone"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={PodcastWinterfell} alt="Podcast Winterfell logo" />
        </a>
        <h2>Want more Thrones?</h2>
        <p>
          Listen in for continued analysis and discussion throughout Season 8.
          Experts will discuss all of the action as it develops through initial
          reaction podcasts and fan livestream shows. The panel will be the
          authoritative source for “dead vs. alive” decisions, should things get
          crazy!
        </p>
        <p>
          Check it out at{' '}
          <a
            href="https://podcastwinterfell.com/?ref=guessthethrone"
            target="_blank"
            rel="noopener noreferrer"
          >
            PodcastWinterfell.com
          </a>
          .
        </p>
      </Tile>
    </FeaturedTileRow>
  )
}

export const DonateTile = () => {
  return (
    <DonateTileContainer>
      <h2>Enjoy Guess the Throne?</h2>
      <p>
        We built Guess the Throne for the community of fellow fans, and it’s
        100% free! If interested, we would appreciate any donation to help
        offset operating costs. Even better, 50% of donations will go to Emilia
        Clarke’s charity, SameYou. Thanks for your support!
      </p>
      <a
        href="https://fundly.com/guess-the-throne"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button medium>Donate</Button>
      </a>
    </DonateTileContainer>
  )
}

export const FeaturedGroupsComponent = () => {
  return (
    <FeaturedGroups>
      <Link to="/groups/ITlIEtIVbspqqppJwDF3">
        <img src={BarstoolFeature} alt="barstool sports featured group" />
      </Link>
      <Link to="/groups/aC1AhmfCEkl8jeuYL5eA">
        <img src={TowerOfBabble} alt="tower of babble featured group" />
      </Link>
    </FeaturedGroups>
  )
}

const FeaturedGroups = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0 2rem;

  a {
    width: 33%;

    img {
      width: 100%;
    }
  }

  ${media.forSmallOnly`
    flex-direction: column;

    a {
      width: 70%;
      margin-bottom: 1rem;

      &:last-child {
        margin: 0;
      }
    }
  `}
`

const FeaturedTileRow = styled.div`
  display: flex;

  > div {
    width: 50%;

    * {
      text-align: center;
    }

    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }

  ${media.forSmallOnly`
    flex-direction: column;

    > div {
      margin: 0 0 1rem !important;
      width: 100%;
    }
  `}
`

const DeathTrackerTile = styled(Tile)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    text-align: center;
    font-size: 1.2rem;
  }

  button {
    display: block;
    margin: 1rem auto 0;
  }

  span {
    display: block;
    text-align: center;
    color: ${props => props.theme.grayText};
  }
`

const DonateTileContainer = styled(Tile)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  button {
    display: block;
    margin: 0 auto;
  }
`

const setupGroupData = groups => {
  return groups.map(group => ({
    ...group,
    privacy: group.isUnlisted ? 'Unlisted' : 'Public',
    numberOfMembers: group.entries ? group.entries.length : 0,
  }))
}

const GoToEntryCell = row => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Link to={`/groups/${row.original.associatedGroup}/${row.original.id}`}>
        {row.value}
      </Link>
    </div>
  )
}

const GoToEntryGroupCell = row => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Link to={`/groups/${row.original.associatedGroup}`}>{row.value}</Link>
    </div>
  )
}

const GoToGroupCell = row => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Link to={`/groups/${row.original.id}`}>{row.value}</Link>
    </div>
  )
}

const db = firebase.firestore()
const getAllGroupsDetail = async user => {
  const groupRequests = user.groups.map(group =>
    db
      .collection('groups')
      .doc(group)
      .get()
  )

  const requests = await Promise.all(groupRequests)
  return requests.reduce((accu, item) => {
    const group = item.data()
    if (group) {
      group.id = item.id
      accu.push(group)
    }

    return accu
  }, [])
}

const getAllPublicGroupsDetail = async () => {
  const snapshot = await db
    .collection('groups')
    .where('isUnlisted', '==', false)
    .get()

  let data = []
  if (snapshot.docs.length > 0) {
    data = snapshot.docs.map(doc => {
      let document = doc.data()
      document.id = doc.id

      return document
    })
  }

  return data
}

export class HomeAuthedPageComponent extends React.Component {
  state = { groups: [], publicEntries: [], loading: true }

  async componentDidMount() {
    const { user } = this.props
    let groups = []
    let publicEntries = []

    if (user && user.groups) {
      groups = await getAllGroupsDetail(user)
    }

    publicEntries = await getAllPublicGroupsDetail()

    this.setState({ groups, publicEntries, loading: false })
  }

  async componentDidUpdate(prevProps) {
    const { user } = this.props

    if (
      user &&
      user.groups &&
      prevProps.user &&
      prevProps.user.groups.length !== user.groups.length
    ) {
      const groups = await getAllGroupsDetail(user)

      this.setState({ groups })
    }
  }

  renderEntries = () => {
    const { entries } = this.props
    return (
      <>
        <h2>Entries</h2>
        {entries && entries.length ? (
          <Table
            columns={[
              { Header: 'Rank', accessor: 'rank' },
              {
                Header: 'Name',
                accessor: 'name',
                Cell: GoToEntryCell,
                minWidth: 200,
              },
              {
                Header: 'Group',
                accessor: 'associatedGroupName',
                Cell: GoToEntryGroupCell,
              },
              {
                Header: 'Score',
                accessor: 'totalScore',
              },
              {
                Header: 'Max',
                accessor: 'maxScore',
              },
            ]}
            data={entries}
          />
        ) : (
          <p>
            You do not have any active entries. Please create or join a group to
            create one.
          </p>
        )}
      </>
    )
  }

  render() {
    const { groups, loading, publicEntries } = this.state
    const { analytics } = this.props

    if (loading) {
      return (
        <PageWrapper>
          <Loading />
        </PageWrapper>
      )
    }

    return (
      <PageWrapper tiled={!LOCK_MODE}>
        {LOCK_MODE && <DonateTile />}
        <DashboardHeader>
          {!LOCK_MODE && (
            <div>
              <h1>My Dashboard</h1>
              <p>
                Below are the groups you have a stake in and the entries you
                have created.
              </p>
            </div>
          )}

          {!LOCK_MODE && (
            <Link to="/create-group">
              <Button medium>Create Group</Button>
            </Link>
          )}
        </DashboardHeader>
        {!LOCK_MODE && (
          <>
            <h2 style={{ marginTop: '1rem' }}>Groups You Have Created</h2>
            {groups.length ? (
              <Table
                columns={[
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: GoToGroupCell,
                    minWidth: 200,
                  },
                  {
                    Header: 'Description',
                    accessor: 'description',
                    minWidth: 250,
                  },
                  {
                    Header: 'Members',
                    accessor: 'numberOfMembers',
                  },
                  {
                    Header: 'Privacy',
                    accessor: 'privacy',
                  },
                ]}
                data={setupGroupData(groups)}
              />
            ) : (
              <p>
                You have not created any groups yet.{' '}
                <Link to="/create-group">Create one?</Link>
              </p>
            )}
          </>
        )}

        {LOCK_MODE ? <Tile>{this.renderEntries()}</Tile> : this.renderEntries()}

        <Tile minHeight={0} fluid>
          Scoring will updated within 48 hours after the show airs to avoid
          spoilers for users in different time zones.{' '}
          <a
            href="https://twitter.com/GuessTheThrone"
            rel="noopener noreferrer"
            target="_blank"
          >
            Follow us
          </a>{' '}
          on Twitter for more updates.
        </Tile>
        {LOCK_MODE ? <FeaturedTiles analytics={analytics} /> : null}

        {!LOCK_MODE && (
          <>
            <h2 style={{ marginTop: '2rem' }}>Public Groups You Can Join</h2>
            <p
              style={{
                fontStyle: 'italic',
                color: '#959595',
                fontSize: '.9rem',
              }}
            >
              Looking to join a friend's group? Have them send you a link.
            </p>
            <FeaturedGroupsComponent />
            <Table
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  Cell: GoToGroupCell,
                  minWidth: 200,
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                  minWidth: 250,
                },
                {
                  Header: 'Members',
                  accessor: 'numberOfMembers',
                },
                {
                  Header: 'Privacy',
                  accessor: 'privacy',
                },
              ]}
              data={setupGroupData(publicEntries)}
              defaultSorted={[
                {
                  id: 'numberOfMembers',
                  desc: true,
                },
              ]}
            />
          </>
        )}
        {LOCK_MODE && (
          <Tile>
            <h2 style={{ marginTop: '1rem' }}>Groups You Have Created</h2>
            {groups.length ? (
              <Table
                columns={[
                  {
                    Header: 'Name',
                    accessor: 'name',
                    Cell: GoToGroupCell,
                    minWidth: 200,
                  },
                  {
                    Header: 'Description',
                    accessor: 'description',
                    minWidth: 250,
                  },
                  {
                    Header: 'Members',
                    accessor: 'numberOfMembers',
                  },
                  {
                    Header: 'Privacy',
                    accessor: 'privacy',
                  },
                ]}
                data={setupGroupData(groups)}
              />
            ) : (
              <p>Entries are locked and you cannot create a group.</p>
            )}
          </Tile>
        )}
      </PageWrapper>
    )
  }
}

export const HomeAuthedPage = props => (
  <AnalyticsConsumer>
    {analytics => (
      <EntriesConsumer>
        {({ entries }) => (
          <HomeAuthedPageComponent
            {...props}
            entries={entries}
            analytics={analytics}
          />
        )}
      </EntriesConsumer>
    )}
  </AnalyticsConsumer>
)
