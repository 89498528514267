import React from 'react'
import styled from 'styled-components'
import { media } from '../style/theme'

const Tiled = styled.div`
  background: ${props => props.theme.white};
  box-shadow: 0 4px 4px 1px #a6c6ce;
  max-width: 1200px;
  min-height: ${props => props.minHeight};
  padding: 2rem;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 1rem;
  ${props => props.fluid && `width: 100%`}

  ${media.forSmallOnly`
    padding: 1rem;
  `};
`

export const Tile = ({
  children,
  minHeight = '250px',
  style = {},
  fluid = false,
  className = '',
}) => {
  return (
    <Tiled
      minHeight={minHeight}
      style={style}
      fluid={fluid}
      className={className}
    >
      {children}
    </Tiled>
  )
}
