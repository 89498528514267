import './polyfills.js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { ScrollToTop } from './components'
import {
  AuthProvider,
  IsFromProductHuntProvider,
  AnalyticsProvider,
} from './context'
import { theme } from './style'

import * as Sentry from '@sentry/browser'

import 'normalize.css'
import 'rodal/lib/rodal.css'
import 'react-table/react-table.css'

Sentry.init({
  dsn: 'https://ed21667bcc5f45118e9e1ee1f77291ed@sentry.io/1422293',
})

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <AuthProvider>
        <IsFromProductHuntProvider>
          <AnalyticsProvider>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </AnalyticsProvider>
        </IsFromProductHuntProvider>
      </AuthProvider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
