import React from 'react'
import ReactTable from 'react-table'
import styled from 'styled-components'

const TableContainer = styled.div`
  margin-bottom: 2rem;

  .ReactTable .rt-thead .rt-tr {
    background-color: ${props => props.theme.primaryColor};

    .rt-th {
      > div {
        color: white;
        align-self: center;
        white-space: pre-wrap;
      }
    }
  }

  .ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 5px 0 0 ${props => props.theme.white};
  }

  .ReactTable .rt-thead .rt-th.-sort-desc {
    box-shadow: inset 0 -5px 0 0 ${props => props.theme.white};
  }

  .ReactTable .rt-thead .rt-th,
  .ReactTable .rt-thead .rt-td {
    display: flex;
    ${props => props.theme.tableHeaderStyles};
    justify-content: center;
  }
`

export const Table = ({ columns, data, ...rest }) => {
  return (
    <TableContainer>
      <ReactTable
        data={data}
        columns={columns}
        className="-striped -highlight"
        defaultPageSize={20}
        showPagination={data.length > 20}
        resizable={false}
        minRows={1}
        {...rest}
      />
    </TableContainer>
  )
}
