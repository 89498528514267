import React from 'react'
import {
  PageWrapper,
  TextInput,
  Button,
  Switch,
  Loading,
  Tile,
  Select,
  Error,
} from '../components'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as yup from 'yup'
import {
  CHARACTERS,
  FIRST_DIE_QUESTION,
  LAST_DIE_QUESTION,
  WIN_THRONE_QUESTION,
} from '../utils/const'
import firebase from '../config/firebase'
import {
  getUserEntry,
  getCharacterByIdForSelect,
} from '../utils/utilityService'
import { media } from '../style/theme'
import {
  WHO_WINS_THE_THRONE,
  WHO_DIES_FIRST,
  WHO_DIES_LAST,
} from '../utils/scoring'
import dlv from 'dlv'

const db = firebase.firestore()
export const SELECT_OPTIONS = CHARACTERS.map(c => ({
  label: c.name,
  value: c.id,
}))

// Sets up data for the form
const characters = {}
CHARACTERS.forEach(c => (characters[c.id] = false))

const FormContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
`

const CharacterContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;

    ${media.forSmallOnly`
      width: 35px;
      height: 35px;
    `}
  }

  p {
    margin: 0;
    font-size: 0.8rem;
  }
`

export const CharacterImageName = ({ character }) => {
  return (
    <CharacterContainer>
      <img src={character.url} alt={character.name} />
      <p>{character.name}</p>
    </CharacterContainer>
  )
}

const SwitchContainer = styled.div`
  ${media.forSmallOnly`
    padding: 0 0 0 1rem;
  `}
`

const CharacterChoice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dcdcdc;
  padding: 0 2rem 1rem;

  &:last-child {
    border: none;
    margin-bottom: 0;
  }

  > p {
    font-size: 1.2rem;
    margin: 0;
  }

  ${media.forSmallOnly`
    padding: 0 0.35rem 1rem;

    > p {
     font-size: 1rem;
    }
  `}
`

const LiveOrDieHeader = styled.div`
  ${props => props.theme.tableHeaderStyles};
  background-color: ${props => props.theme.primaryColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 1rem;

  p {
    color: ${props => props.theme.white};
    margin: 0;
  }

  ${media.forSmallOnly`
    padding: 0 1rem;
  `}
`

const QuestionContainer = styled.div`
  margin-bottom: 1.5rem;
  position: relative;

  label {
    margin-bottom: 0.25rem;
    display: block;

    span {
      color: ${props => props.theme.grayText};
      font-style: italic;
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
`

export const FormGroupSelect = ({
  score,
  label,
  onChange = () => null,
  onBlur = () => null,
  error,
  name,
  ...rest
}) => {
  return (
    <QuestionContainer>
      <label>
        {label} <span>+{score} points</span>
      </label>
      <Select
        {...rest}
        onChange={value => onChange(name, value.value)}
        onBlur={() => onBlur(name)}
      />
      {error ? <Error>{error}</Error> : null}
    </QuestionContainer>
  )
}

export const LiveOrDieComponent = ({
  switchCharacters,
  onChange = () => null,
  stateCharacters = {},
}) => {
  return (
    <>
      <LiveOrDieHeader>
        <p>Character</p>
        <p>Lives or Dies?</p>
      </LiveOrDieHeader>
      {switchCharacters.map(character => {
        return (
          <CharacterChoice key={character.id}>
            <CharacterImageName character={character} />
            <SwitchContainer>
              <Switch
                leftText="Lives"
                rightText="Dies"
                onColor={'#bc372b'}
                onChange={() => onChange(character)}
                checked={Boolean(stateCharacters[character.id])}
              />
            </SwitchContainer>
          </CharacterChoice>
        )
      })}
    </>
  )
}

// This handles both edit and create mode
export const CreateEntryForm = props => {
  const {
    entryDetail,
    user,
    history,
    match: { params },
  } = props
  const editMode = Boolean(entryDetail)
  const initialValues = {
    name: '',
    characters,
    whoDiesFirst: '',
    whoDiesLast: '',
    winsIronThrone: '',
  }
  const userFirstName = dlv(props, 'user.firstName')

  if (userFirstName) {
    initialValues.name = `${userFirstName}'s Entry`
  }

  if (editMode) {
    initialValues.name = entryDetail.name
    initialValues.characters = entryDetail.characters
    initialValues.whoDiesFirst = entryDetail.whoDiesFirst
    initialValues.whoDiesLast = entryDetail.whoDiesLast
    initialValues.winsIronThrone = entryDetail.winsIronThrone
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .required('Entry name is required.')
          .max(50, 'Name cannot be more than 50 characters.'),
        whoDiesFirst: yup.string().required('Who dies first is required.'),
        whoDiesLast: yup.string().required('Who dies last is required.'),
        winsIronThrone: yup
          .string()
          .required('Who wins Iron Throne is required.'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        const documentToAdd = {
          ...values,
        }

        // Update mode
        if (editMode) {
          documentToAdd.updatedOn = firebase.firestore.Timestamp.fromDate(
            new Date()
          )

          try {
            await await db
              .collection('entries')
              .doc(entryDetail.id)
              .set(documentToAdd, { merge: true })

            history.push({
              pathname: `/groups/${params.groupId}`,
            })
          } catch (error) {
            console.error('Error updating document: ', error)
          }
        } else {
          documentToAdd.createdBy = user.userId
          documentToAdd.createdOn = firebase.firestore.Timestamp.fromDate(
            new Date()
          )
          documentToAdd.associatedGroup = params.groupId
          documentToAdd.associatedGroupName = props.groupDetail.name
          documentToAdd.rank = 0

          db.collection('entries')
            .add(documentToAdd)
            .then(function(docRef) {
              setSubmitting(false)

              history.push({
                pathname: `/groups/${params.groupId}`,
                state: { entry: documentToAdd, fromCreateEntry: true },
              })
            })
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => {
        const { name, characters } = values
        const nameError = touched.name && errors.name
        const whoDiesFirstError = touched.whoDiesFirst && errors.whoDiesFirst
        const whoDiesLastError = touched.whoDiesLast && errors.whoDiesLast
        const whoWinsThroneError =
          touched.winsIronThrone && errors.winsIronThrone

        return (
          <form onSubmit={handleSubmit}>
            <Tile>
              <h1>
                {editMode ? (
                  <span>
                    Edit entry: <b>{entryDetail.name}</b>
                  </span>
                ) : (
                  'Create Entry'
                )}
              </h1>
              <p>
                {editMode
                  ? 'Entries lock at the beginning of the season premier.'
                  : 'Once you create an entry you cannot update it so choose wisely! Each correct guess is 1 point.'}
              </p>
              <FormContainerStyles>
                {editMode ? null : (
                  <TextInput
                    placeholder="Entry Name"
                    value={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={nameError}
                    name="name"
                    style={{ marginBottom: '2rem' }}
                  />
                )}

                <LiveOrDieComponent
                  onChange={character =>
                    setFieldValue(
                      `characters.${character.id}`,
                      !characters[character.id]
                    )
                  }
                  switchCharacters={CHARACTERS}
                  stateCharacters={characters}
                />
              </FormContainerStyles>
            </Tile>
            <Tile>
              <h2 style={{ marginBottom: '1rem' }}>Bonus Questions</h2>
              <p>
                If no one wins the iron throne, the question will not be scored.
              </p>
              <FormContainerStyles>
                <FormGroupSelect
                  label={FIRST_DIE_QUESTION}
                  score={WHO_DIES_FIRST}
                  name="whoDiesFirst"
                  options={SELECT_OPTIONS}
                  value={getCharacterByIdForSelect(values.whoDiesFirst)}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={whoDiesFirstError}
                />
                <FormGroupSelect
                  label={LAST_DIE_QUESTION}
                  score={WHO_DIES_LAST}
                  name="whoDiesLast"
                  options={SELECT_OPTIONS}
                  value={getCharacterByIdForSelect(values.whoDiesLast)}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={whoDiesLastError}
                />
                <FormGroupSelect
                  label={WIN_THRONE_QUESTION}
                  score={WHO_WINS_THE_THRONE}
                  name="winsIronThrone"
                  options={SELECT_OPTIONS}
                  value={getCharacterByIdForSelect(values.winsIronThrone)}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={whoWinsThroneError}
                />
                <Button
                  type="submit"
                  style={{ alignSelf: 'center', marginTop: '1rem' }}
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </Button>
              </FormContainerStyles>
            </Tile>
          </form>
        )
      }}
    </Formik>
  )
}

export class CreateEntryPage extends React.Component {
  state = { groupDetail: null }
  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    const groupDetailReq = await db
      .collection('groups')
      .doc(params.groupId)
      .get()

    const groupDetail = groupDetailReq.data()

    this.setState({ groupDetail })
  }

  render() {
    const { groupDetail } = this.state
    const { props } = this

    if (!groupDetail || !props.user) {
      return (
        <PageWrapper>
          <Loading />
        </PageWrapper>
      )
    }

    if (getUserEntry(groupDetail, props.user.userId)) {
      return <p>You can only create one entry per group.</p>
    }

    return (
      <PageWrapper>
        <CreateEntryForm groupDetail={groupDetail} {...props} />
      </PageWrapper>
    )
  }
}
