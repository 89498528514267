import { CHARACTERS } from './const'

/**
 * Scoring values
 * Each live/die correct guess is worth one point
 */
export const WHO_WINS_THE_THRONE = 6
export const WHO_DIES_FIRST = 3
export const WHO_DIES_LAST = 3

export const calculateTotalPotentialScore = () => {
  return (
    CHARACTERS.length + WHO_WINS_THE_THRONE + WHO_DIES_FIRST + WHO_DIES_LAST
  )
}
