import React from 'react'
import styled from 'styled-components'
import { media } from '../style/theme'
import { Link } from 'react-router-dom'

const FooterContainer = styled.div`
  background-color: ${props => props.theme.primaryColor};
  padding: 2rem 2rem;

  ${media.forSmallOnly`
    padding: 1rem 2rem;
  `}

  p {
    color: ${props => props.theme.white};
    margin: 0;

    a {
      color: ${props => props.theme.white};
      text-decoration: underline;
    }
  }
`

export const Footer = () => {
  return (
    <FooterContainer>
      <div>
        <p>
          Made with{' '}
          <span role="img" aria-label="heart">
            ❤️
          </span>{' '}
          by the <a href="https://www.itsadate.app">It's a Date</a> team
        </p>
        <p style={{ marginBottom: '1rem' }}>
          <Link to="/privacy-policy">Privacy Policy</Link> |{' '}
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </p>
        <p style={{ fontStyle: 'italic', fontSize: '.8rem' }}>
          We are not affiliated, associated, authorized, endorsed by, or in any
          way officially connected with HBO, Game of Thrones, or any of its
          subsidiaries or its affiliates.
        </p>
      </div>
    </FooterContainer>
  )
}
