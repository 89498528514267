import React from 'react'
import firebase from '../config/firebase'
import { getAllEntriesDetail, addScoreToEntries } from '../utils/utilityService'
import { Loading } from '../components'

const EntriesContext = React.createContext()

export class EntriesProvider extends React.Component {
  state = {
    scores: null,
    entries: null,
  }

  async componentDidMount() {
    const { user } = this.props
    const db = firebase.firestore()

    if (!user) {
      return
    }

    const entries = await getAllEntriesDetail(user)

    const groupRequests = entries.map(entry =>
      db
        .collection('scores')
        .doc(entry.associatedGroup)
        .get()
    )
    const requests = await Promise.all(groupRequests)

    let scores = {}
    requests.forEach(item => {
      const scoresForGroup = item.data()

      if (scores) {
        scores = { ...scores, ...scoresForGroup.entries }
      }
    })

    const entriesWithScores = addScoreToEntries(entries, scores)

    this.setState({ entries: entriesWithScores, scores })
  }

  render() {
    const { children, user } = this.props
    const { entries } = this.state

    if (user && !entries) {
      return <Loading />
    }

    return (
      <EntriesContext.Provider value={this.state}>
        {children}
      </EntriesContext.Provider>
    )
  }
}

export const EntriesConsumer = EntriesContext.Consumer
