import characters from './characters.json'

export const CHARACTERS = characters
export const PREMIER_DATE = 1555290000000
export const LOCK_MODE = process.env.REACT_APP_LOCK_MODE

export const FIRST_DIE_QUESTION =
  'Which character will die first during season 8?'
export const LAST_DIE_QUESTION =
  'Which character will be the final death of season 8?'
export const WIN_THRONE_QUESTION = 'Who wins the iron throne?'

export const TRENDY_PUNS = [
  'Jump into the Frey',
  "Hotpie's Missing Recipe",
  'We know nothing',
  "What's Hodor's favorite cereal? Raisin Bran",
  '**Overheard after the red wedding** - I heard the party died after I left.',
  'I drink and I know things',
  'When you play the game of thrones, you win or you die.',
  "Gendry's Helmet",
  'Hold the door...',
  'Winter is coming',
  'A Lannister always pays his debts',
]

export const DEMO_CHARACTERS = characters.slice(0, 3)
export const DEMO_STUB_ENTRY = [
  {
    name: 'Jon Snow',
    id: 'jonsnow',
    url:
      'https://res.cloudinary.com/itsadateapp/image/upload/q_auto:best,r_max,w_60,h_60,c_fill,g_face/v1553315040/Jon_Snow.png',
    isDeadAtTheEnd: true,
    isCorrect: true,
  },
  {
    name: 'Daenerys Targaryen',
    id: 'daenerystargaryen',
    url:
      'https://res.cloudinary.com/itsadateapp/image/upload/q_auto:best,r_max,w_60,h_60,c_fill,g_face/v1553315040/Daenerys.png',
    isDeadAtTheEnd: false,
    isCorrect: null,
  },
  {
    name: 'Tyrion Lannister',
    id: 'tyrionlannister',
    url:
      'https://res.cloudinary.com/itsadateapp/image/upload/q_auto:best,r_max,w_60,h_60,c_fill,g_face/v1553315040/Tyrion.png',
    isDeadAtTheEnd: true,
    isCorrect: false,
  },
]
