import React from 'react'
import firebase from '../config/firebase'
import { Loading } from '../components'

const AnalyticsContext = React.createContext()

export class AnalyticsProvider extends React.Component {
  state = {
    userTotal: null,
    characterFates: {},
    showEnded: false,
  }

  componentDidMount() {
    const db = firebase.firestore()
    this.unregisterAnalyticsObserver = db
      .collection('aggregations')
      .doc('totals')
      .onSnapshot(doc => {
        const analyticsData = doc.data()
        console.log('ANALYTICS =>', analyticsData)

        this.setState({ ...analyticsData })
      })
  }

  componentWillUnmount() {
    this.unregisterAnalyticsObserver()
  }

  render() {
    const { userTotal } = this.state
    const { children } = this.props

    if (!userTotal) {
      return <Loading />
    }
    return (
      <AnalyticsContext.Provider value={this.state}>
        {children}
      </AnalyticsContext.Provider>
    )
  }
}

export const AnalyticsConsumer = AnalyticsContext.Consumer
