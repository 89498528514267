import React from 'react'
import styled from 'styled-components'
import ExclamationIcon from '../assets/exclamation-circle.svg'
import { media } from '../style/theme'

const InfoPanelContainer = styled.div`
  background: #ececec;
  width: 100%;
  padding: 0.75rem 2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #5d5d5d;
  margin-bottom: 1rem;
  font-size: 0.8rem;

  .exclamation-icon {
    margin-right: 1rem;

    ${media.forSmallOnly`
      width: 20px;
      height: 20px;
    `}
  }

  > div {
    flex: 1;
  }

  ${media.forSmallOnly`
    padding: 0.75rem 1rem;
  `}
`

export const InfoPanel = ({ children, noIcon = false, style = {} }) => {
  return (
    <InfoPanelContainer style={style}>
      {!noIcon && (
        <img
          src={ExclamationIcon}
          className="exclamation-icon"
          alt="alert icon"
        />
      )}

      <div>{children}</div>
    </InfoPanelContainer>
  )
}
