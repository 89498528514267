import React from 'react'
import { PageWrapper } from '../components'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from '../config/firebase'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FancyGraphic, HowItWorksSection } from '../components'
import { media } from '../style/theme'
import SignUpFancyImage from '../assets/fireAndIce/landingpage-4.svg'
import * as firebaseui from 'firebaseui'
import { IsFromProductHuntConsumer } from '../context'
import { LOCK_MODE } from '../utils/const'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: 'image', // 'audio'
        size: 'invisible', // 'invisible' or 'compact'
        badge: 'bottomleft', //' bottomright' or 'inline' applies to invisible.
      },
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
}

const LoginHelpText = styled.p`
  margin-bottom: 2rem;
`
const LegalText = styled.div`
  max-width: 500px;
  margin: 2rem auto 0;

  p {
    font-size: 0.7rem;
    text-align: center;
    font-style: italic;
  }
`

const FancyLoginHeaderContainer = styled.div`
  img {
    display: block;
    margin: 0 auto 1rem;
    width: 200px;
  }

  h1 {
    text-align: center;
    font-size: 2rem;

    ${media.forSmallOnly`
      font-size: 1.5rem;
    `}
  }
`

const FancyLoginHeader = () => {
  return (
    <FancyLoginHeaderContainer>
      <img src={SignUpFancyImage} alt="Sign up for Guess the Thone" />
      <h1>Welcome to Guess the Throne!</h1>
      <LoginHelpText style={{ textAlign: 'center' }}>
        The easiest way to create or join a death pool with your friends for
        free.
      </LoginHelpText>
    </FancyLoginHeaderContainer>
  )
}

const NormalLoginHeader = () => {
  return (
    <div>
      <h1>Create Profile</h1>
      <LoginHelpText>
        Sign up for an account to join your friends and create your death pool!
      </LoginHelpText>
    </div>
  )
}

const ProvideMoreSignInContextContainer = styled.div`
  margin: 3rem 0 2rem 0;
  padding-top: 3rem;
  border-top: 1px solid #d8d8d8;

  h2 {
    font-size: 2rem;
    text-align: center;

    ${media.forSmallOnly`
      font-size: 1.5rem;
    `}
  }

  p {
    text-align: center;
  }
`

const ProvideMoreSignInContext = () => {
  return (
    <ProvideMoreSignInContextContainer>
      <h2>You've been invited to join a death pool group</h2>
      <p>
        Sign in to create an account, join the group, and select your picks.
      </p>
    </ProvideMoreSignInContextContainer>
  )
}

export const SignUpPageComponent = ({ location, isFromProductHunt }) => {
  const fromLocation = location && location.state && location.state.from
  if (fromLocation) {
    uiConfig.signInSuccessUrl = location.state.from.pathname
  }

  if (isFromProductHunt) {
    uiConfig.signInSuccessUrl = '/?ref=producthunt'
  }

  return (
    <PageWrapper
      tiled={!fromLocation}
      noBackground={fromLocation}
      containerStyle={{ paddingTop: '2rem' }}
    >
      {fromLocation && !LOCK_MODE ? (
        <FancyLoginHeader />
      ) : (
        <NormalLoginHeader />
      )}
      {fromLocation && !LOCK_MODE ? <HowItWorksSection /> : <FancyGraphic />}
      {fromLocation && !LOCK_MODE ? <ProvideMoreSignInContext /> : null}
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      <LegalText>
        <p>
          By creating an account, you agree to our{' '}
          <Link to="/terms-and-conditions">terms and conditions</Link> and{' '}
          <Link to="/privacy-policy">privacy policy</Link>.
        </p>

        <p>
          We are not affiliated, associated, authorized, endorsed by, or in any
          way officially connected with HBO, Game of Thrones, or any of its
          subsidiaries or its affiliates.
        </p>
      </LegalText>
    </PageWrapper>
  )
}

export const SignUpPage = props => {
  return (
    <IsFromProductHuntConsumer>
      {({ isFromProductHunt }) => {
        return (
          <SignUpPageComponent
            {...props}
            isFromProductHunt={isFromProductHunt}
          />
        )
      }}
    </IsFromProductHuntConsumer>
  )
}
