import React from 'react'
import styled, { css } from 'styled-components'
import { media } from '../style'
import { Tile } from './'

const PageWrapperBackgroundStyles = styled.div`
  background-image: ${props =>
    props.noBackground
      ? 'transparent'
      : 'radial-gradient(47% 118%, #f0fffa 32%, #8da7af 100%)'};
  min-height: calc(100vh - ${props => props.theme.desktopHeaderHeight});
  padding: 5rem;

  ${media.forSmallMediumOnly`
    padding: 3rem;
    `}

  ${media.forSmallOnly`
    padding: 1rem;
    min-height: calc(100vh - ${props => props.theme.mobileHeaderHeight});
  `};
`

const PageWrapperContainer = styled.div`
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;

  ${({ fluid }) =>
    !fluid &&
    css`
      @media (min-width: 576px) {
        max-width: 540px;
      }

      @media (min-width: 768px) {
        max-width: 720px;
      }

      @media (min-width: 992px) {
        max-width: 960px;
      }

      @media (min-width: 1200px) {
        max-width: 1140px;
      }
    `}
`

export const PageWrapper = ({
  children,
  fluid = false,
  tiled = false,
  noBackground = false,
  containerStyle = {},
  tileStyle = {},
}) => {
  return (
    <PageWrapperBackgroundStyles
      tiled={tiled}
      noBackground={noBackground}
      style={containerStyle}
    >
      {tiled ? (
        <Tile style={tileStyle}>{children}</Tile>
      ) : (
        <PageWrapperContainer fluid={fluid}>{children}</PageWrapperContainer>
      )}
    </PageWrapperBackgroundStyles>
  )
}
