import React from 'react'
import { useSpring, animated } from 'react-spring'
import styled, { css } from 'styled-components'
import { media } from '../style/theme'
import Logo from '../assets/throneLogo.svg'
import { Link } from 'react-router-dom'
import { Button } from './'
import { LOCK_MODE } from '../utils/const'

import Map from '../assets/fireAndIce/landingpage-1.svg'
import Mountains from '../assets/fireAndIce/landingpage-2.svg'
import Dragon from '../assets/fireAndIce/landingpage-3.svg'
import Lion from '../assets/fireAndIce/landingpage-4.svg'

const SIZE = '100vh'
const VIEWPORT_OVERFLOW = '10%'

const LionDragonStyles = css`
  width: 550px;
  height: 400px;

  ${media.forSmallMediumOnly`
    width: 450px;
    height: 300px;
  `}

  @media (max-width: 600px) {
    width: 300px;
    height: 150px;
  }
`

const HeroContentContainer = styled.div`
  position: relative;
  z-index: 10;
  max-width: 625px;
  margin: 4rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0.5rem;
    text-align: center;
  }

  img {
    width: 100%;
  }

  ${media.forSmallOnly`
    padding: 0 3rem;
  `}
`

const FancyGraphicContainer = styled.div`
  width: 100%;
  height: calc(${SIZE} - ${props => props.theme.desktopHeaderHeight});
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  ${media.forSmallOnly`
    height: calc(${SIZE} - ${props => props.theme.mobileHeaderHeight});
  `}

  .card1,
  .card2,
  .card3,
  .card4,
  .card5 {
    position: absolute;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    will-change: transform;
  }

  .card1 {
    width: 100%;
    height: calc(${SIZE} - ${props => props.theme.desktopHeaderHeight});
    top: 0;
    bottom: 0;
    left: 0;
    background-image: url(${Map});
  }

  .card2 {
    bottom: 0;
    width: calc(100% + ${VIEWPORT_OVERFLOW});
    left: calc(-${VIEWPORT_OVERFLOW} / 2);
    bottom: -25%;
    height: 100%;
    background-image: url(${Mountains});
  }

  .card3 {
    bottom: 0;
    left: 10%;
    background-image: url(${Dragon});
    ${LionDragonStyles}

    ${media.forSmallOnly`
      left: 0;
    `}

    @media (max-width: 600px) {
      left: 10px;
    }
  }

  .card4 {
    bottom: 0;
    right: 10%;
    background-image: url(${Lion});
    ${LionDragonStyles}

    ${media.forSmallOnly`
      right: 0;
    `}

    @media (max-width: 600px) {
      right: -30px;
    }
  }
`

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
// const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`
const trans2 = (x, y) => `translate3d(${x / 11}px,${0}px,0)`
const trans3 = (x, y) => `translate3d(${x / 4 - 40}px,${0}px,0)`
const trans4 = (x, y) => `translate3d(${x / 13}px,${0}px,0)`

export const FancyHome = () => {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))
  return (
    <FancyGraphicContainer
      className="container"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <HeroContentContainer>
        <img src={Logo} alt="Guess the throne logo" />
        {LOCK_MODE ? (
          <h1>The Game of Thrones death pool app.</h1>
        ) : (
          <>
            <h1>
              The easiest way to create a death pool with your friends for free.
            </h1>
            <ol>
              <li>Create a group, or join one!</li>
              <li>Submit your death pool</li>
              <li>Invite your friends</li>
              <li>We handle scoring and everything else!</li>
            </ol>
            <Link to="/sign-up">
              <Button>Get Started</Button>
            </Link>
            <p
              style={{
                fontStyle: 'italic',
                fontSize: '.7rem',
                textAlign: 'center',
                maxWidth: '450px',
              }}
            >
              We are not affiliated, associated, authorized, endorsed by, or in
              any way officially connected with HBO, Game of Thrones, or any of
              its subsidiaries or its affiliates.
            </p>
          </>
        )}
      </HeroContentContainer>
      <div className="card1" />
      <animated.div
        className="card2"
        style={{ transform: props.xy.interpolate(trans2) }}
      />
      <animated.div
        className="card3"
        style={{
          transform: props.xy.interpolate(trans3),
        }}
      />
      <animated.div
        className="card4"
        style={{ transform: props.xy.interpolate(trans4) }}
      />
    </FancyGraphicContainer>
  )
}
