import React from 'react'
import styled from 'styled-components'
import ReactSelect from 'react-select'
import { theme } from '../style'

const SelectContainer = styled.div``

export const Select = props => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: theme.defaultInputBackgroundColor,
      border: `2px solid ${
        props.borderColor
          ? props.borderColor
          : theme.defaultInputBackgroundColor
      }`,
    }),
    input: (provided, state) => ({
      ...provided,
      padding: '4px 8px',
      lineHeight: '1.5rem',
    }),
    singleValue: (provided, state) => ({
      ...provided,
    }),
  }

  return (
    <SelectContainer>
      <ReactSelect maxMenuHeight={200} styles={customStyles} {...props} />
    </SelectContainer>
  )
}
