import React from 'react'
import {
  FancyHome,
  PageWrapper,
  Button,
  HowItWorksSection,
  Tile,
} from '../components'
import { PREMIER_DATE, LOCK_MODE } from '../utils/const'
import styled from 'styled-components'
import { media } from '../style/theme'
import Countdown from 'react-countdown-now'
import { Link } from 'react-router-dom'
import { FeaturedGroupsComponent } from './HomeAuthed'
import { AnalyticsConsumer } from '../context'
import { FeaturedTiles, DonateTile } from './HomeAuthed'

import KingsLanding from '../assets/kingslanding.png'

const PowerImage = styled.img`
  width: 30%;
  margin: 0 auto;
  display: block;
  max-width: 500px;
  margin-bottom: 2rem;

  ${media.forSmallOnly`
    width: 80%;
  `}
`

const BracketDemoContainer = styled.div`
  margin: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  overflow: hidden;
`

const FancyCountdownContainer = styled.div`
  display: flex;
  justify-content: space-around;
`
const FancyCountdown = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;

  > div {
    color: ${props => props.theme.defaultFontColor} !important;

    &:first-child {
      font-size: 2.5rem;
      font-weight: bold;
    }
    &:last-child {
      font-size: 1.5rem;
    }
  }

  ${media.forSmallMediumOnly`
    padding: 2rem;

    > div {

    &:first-child {
      font-size: 2rem;
      font-weight: bold;
    }
    &:last-child {
      font-size: 1rem;
    }
  }
  `}

  ${media.forSmallOnly`
    padding: 1rem;

    > div {

    &:first-child {
      font-size: 2rem;
      font-weight: bold;
    }
    &:last-child {
      font-size: 1rem;
    }
  }
  `}
`

const EntryLockContainer = styled(Tile)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`

export class HomePage extends React.Component {
  render() {
    return (
      <>
        <FancyHome />
        {!LOCK_MODE ? (
          <PageWrapper noBackground>
            <h1
              style={{
                textAlign: 'center',
                fontSize: '2.5rem',
              }}
            >
              Featured Groups You Can Join
            </h1>
            <FeaturedGroupsComponent />

            <h1
              style={{
                textAlign: 'center',
                fontSize: '2.5rem',
              }}
            >
              How it Works
            </h1>
            <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
              Create a group with your friends or join one. Once you do, it's
              time to fill out your death pool!
            </p>
            <HowItWorksSection />
            <BracketDemoContainer
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '2rem',
                marginTop: '3rem',
              }}
            >
              <AnalyticsConsumer>
                {({ userTotal }) => {
                  if (!userTotal) {
                    return null
                  }

                  return (
                    <h1 style={{ textAlign: 'center' }}>
                      Join over {userTotal.toLocaleString()} others before the
                      show starts!
                    </h1>
                  )
                }}
              </AnalyticsConsumer>

              <Countdown
                date={PREMIER_DATE}
                renderer={({ days, hours, minutes, seconds }) => {
                  return (
                    <FancyCountdownContainer>
                      <FancyCountdown>
                        <div>{days}</div>
                        <div>Days</div>
                      </FancyCountdown>
                      <FancyCountdown>
                        <div>{hours}</div>
                        <div>Hours</div>
                      </FancyCountdown>
                      <FancyCountdown>
                        <div>{minutes}</div>
                        <div>Minutes</div>
                      </FancyCountdown>
                      <FancyCountdown>
                        <div>{seconds}</div>
                        <div>Seconds</div>
                      </FancyCountdown>
                    </FancyCountdownContainer>
                  )
                }}
              />
              <Link to="/sign-up">
                <Button>Get Started</Button>
              </Link>
            </BracketDemoContainer>
          </PageWrapper>
        ) : (
          <AnalyticsConsumer>
            {analytics => {
              if (!analytics) {
                return null
              }

              return (
                <PageWrapper>
                  <EntryLockContainer>
                    <h1>Entries have locked!</h1>
                    <PowerImage src={KingsLanding} alt="Kings landing" />
                    <p>
                      Thanks to the {analytics.userTotal.toLocaleString()}{' '}
                      people who signed up. Good luck and enjoy season 8!
                    </p>
                  </EntryLockContainer>
                  <FeaturedTiles analytics={analytics} />
                  <DonateTile />
                </PageWrapper>
              )
            }}
          </AnalyticsConsumer>
        )}
      </>
    )
  }
}
