import React from 'react'
import { PageWrapper } from '../components'
import { AnalyticsConsumer } from '../context'
import { Tile } from '../components'
import { CHARACTERS } from '../utils/const'
import styled from 'styled-components'
import { media } from '../style'
import { ResponsiveRadar } from '@nivo/radar'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { hierarchy } from 'd3-hierarchy'

const ODDS = {
  jorahmormont: 0.58,
  meerareed: 0.01,
  tormundgiantsbane: 0.39,
  bericdondarrion: 0.01,
  gilly: 0.39,
  varys: 0.83,
  jaimelannister: 1.46,
  yaragreyjoy: 0.39,
  melisandre: 0.58,
  davosseaworth: 0.83,
  greyworm: 0.01,
  jonsnow: 16.64,
  eurongreyjoy: 0.58,
  brienneoftarth: 0.58,
  gendrybaratheon: 8.32,
  aryastark: 3.64,
  cerseilannister: 2.33,
  'sandorclegane/thehound': 0.47,
  thenightking: 5.82,
  samwelltarly: 2.91,
  bronn: 0.58,
  theongreyjoy: 0.39,
  daenerystargaryen: 8.32,
  tyrionlannister: 5.82,
  sansastark: 11.65,
  branstark: 26.47,
  qyburn: 0.01,
  missandei: 0.01,
}

const createRadarChartData = (whoWinsThroneAnswers, totalEntries) => {
  return CHARACTERS.map(c => ({
    name: c.id === 'thenightking' ? c.name : c.name.split(' ')[0],
    odds: ODDS[c.id],
    predicted: parseFloat(
      (whoWinsThroneAnswers[c.id] / totalEntries) * 100
    ).toFixed(2),
  }))
}

const createHierarchyData = (data, totalEntries) => {
  const rootData = {
    name: 'All Data',
    children: CHARACTERS.map(c => ({
      name: c.name,
      value: data[c.id],
      label: `${data[c.id].toLocaleString()} (${Math.round(
        (data[c.id] / totalEntries) * 100
      )}%)`,
    })),
  }

  return hierarchy(rootData).data
}

const Treemap = ({ data }) => {
  return (
    <div style={{ height: '400px' }}>
      <ResponsiveTreeMap
        root={data}
        tooltip={node => {
          return (
            <div>
              <b>{node.data.name}:</b> <span>{node.data.label}</span>
            </div>
          )
        }}
        identity="name"
        value="value"
        innerPadding={1}
        outerPadding={1}
        margin={{
          top: 10,
          right: 10,
          bottom: 10,
          left: 10,
        }}
        label="value"
        labelFormat=".0s"
        labelSkipSize={12}
        labelTextColor="inherit:darker(1.2)"
        colors="purple_blue"
        colorBy="value"
        borderColor="inherit:darker(0.3)"
        animate={true}
      />
    </div>
  )
}

const StaticContainer = styled.div`
  div {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  p {
    text-align: center;
  }
`
const HelpText = styled.p``

const RadarContainer = styled.div`
  height: 500px;

  ${media.forSmallOnly`
    height: 300px;
  `}
`

const TileRow = styled.div`
  display: flex;

  > div {
    width: 50%;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }
  }
  ${media.forSmallOnly`
    flex-direction: column;

    > div {
      width: 100%;

      &:first-child {
       margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  `}
`

const Statistic = ({ number = 0, label }) => {
  return (
    <StaticContainer>
      <div>{number.toLocaleString()}</div>
      <p>{label}</p>
    </StaticContainer>
  )
}

const addAnalyticsToCharacters = (characters, analytics) =>
  characters.map(c => ({
    ...c,
    deathRatio: analytics.characterDeathPredictionsRatio[c.id],
    deathPredictions: analytics.characterDeathPredictions[c.id],
  }))

const DEATH_BAR_HEIGHT = '15px'
const DEATH_BAR_BORDER_RADIUS = '5px'

const DeathBarWrapper = styled.div`
  display: flex;
  flex: 1;
`

const DeathBarContainer = styled.div`
  position: relative;
  display: flex;
  height: ${DEATH_BAR_HEIGHT};
  border-radius: ${DEATH_BAR_BORDER_RADIUS};
  width: 100%;
  margin: 0 0.5rem;
  background-color: ${props => props.theme.positiveColor};
`

const DeathBarBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${DEATH_BAR_BORDER_RADIUS};
  height: ${DEATH_BAR_HEIGHT};
  width: ${props => `${props.ratio}%`};
  background-color: ${props => props.theme.negativeColor};
`

const DeathBar = ({ ratio }) => (
  <DeathBarWrapper>
    <span>Dead</span>
    <DeathBarContainer>
      <DeathBarBar ratio={ratio} />
    </DeathBarContainer>
    <span>Alive</span>
  </DeathBarWrapper>
)

const CharacterBarContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  img {
    margin-right: 1rem;
  }

  > div {
    flex: 1;

    p {
      margin: 0 0 0.5rem;
      font-weight: bold;

      span {
        font-weight: 300;
      }
    }
  }
`

const MetricsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 25%;

    ${media.forSmallOnly`
      width: 50%;
    `}
  }
`

const CharacterBar = ({ character }) => {
  const deathPercentage = Math.round(character.deathRatio * 100)
  return (
    <CharacterBarContainer>
      <img src={character.url} alt={character.name} />
      <div>
        <p>
          {character.name} <span>({deathPercentage}%)</span>
        </p>
        <DeathBar ratio={deathPercentage} />
      </div>
    </CharacterBarContainer>
  )
}

export const AnalyticsPage = () => {
  return (
    <AnalyticsConsumer>
      {analytics => {
        const charactersWithAnalytics = addAnalyticsToCharacters(
          CHARACTERS,
          analytics
        ).sort((a, b) => b.deathRatio - a.deathRatio)
        const whoDiesFirstData = createHierarchyData(
          analytics.bonusQuestionTotals.whoDiesFirst,
          analytics.totalEntries
        )
        const whoDiesLastData = createHierarchyData(
          analytics.bonusQuestionTotals.whoDiesLast,
          analytics.totalEntries
        )
        const radarData = createRadarChartData(
          analytics.bonusQuestionTotals.winsIronThrone,
          analytics.totalEntries
        )
          .sort((a, b) => b.predicted - a.predicted)
          .slice(0, 10)

        return (
          <PageWrapper>
            <Tile minHeight={0}>
              <h2>Metrics</h2>
              <MetricsContainer>
                <Statistic
                  label="Total Entries"
                  number={analytics.totalEntries}
                />
                <Statistic
                  label="Perfect Entries"
                  number={analytics.perfectEntriesRemaining}
                />
                <Statistic
                  label="Percent Perfect"
                  number={`${Math.round(analytics.percentPerfect * 100)}%`}
                />
                <Statistic
                  label="Total Groups"
                  number={analytics.totalGroups}
                />
              </MetricsContainer>
            </Tile>
            <Tile>
              <h2>Who Wins the Throne</h2>
              <HelpText>Entries versus Vegas adjusted odds</HelpText>
              <RadarContainer>
                <ResponsiveRadar
                  data={radarData}
                  keys={['odds', 'predicted']}
                  indexBy="name"
                  maxValue={35}
                  margin={{
                    top: 70,
                    right: 80,
                    bottom: 40,
                    left: 80,
                  }}
                  curve="linearClosed"
                  borderWidth={2}
                  gridShape="circular"
                  gridLabelOffset={8}
                  dotSize={8}
                  colors="category10"
                  colorBy="key"
                  legends={[
                    {
                      anchor: 'top-left',
                      direction: 'column',
                      translateX: -50,
                      translateY: -40,
                      itemWidth: 80,
                      itemHeight: 20,
                      itemTextColor: '#999',
                      symbolSize: 12,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              </RadarContainer>
            </Tile>
            <Tile>
              <h2>Most Predicted to Die</h2>
              {charactersWithAnalytics.map(c => (
                <CharacterBar key={c.id} character={c} analytics={analytics} />
              ))}
            </Tile>
            <TileRow>
              <Tile>
                <h2>Who Dies First</h2>
                <Treemap data={whoDiesFirstData} />
              </Tile>
              <Tile>
                <h2>Who Dies Last</h2>
                <Treemap data={whoDiesLastData} />
              </Tile>
            </TileRow>
          </PageWrapper>
        )
      }}
    </AnalyticsConsumer>
  )
}
