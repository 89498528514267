import React from 'react'
import { PageWrapper, TextInput, Button, Switch } from '../components'
import { Formik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import firebase from '../config/firebase'
import KingsLanding from '../assets/kingslanding.png'
import { media } from '../style/theme'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 700px;
`

const PowerImage = styled.img`
  width: 35%;
  margin: 0 auto;
  display: block;
  max-width: 500px;
  margin-bottom: 2rem;

  ${media.forSmallOnly`
    width: 80%;
  `}
`

const CreateGroupForm = props => {
  return (
    <Formik
      initialValues={{ name: '', description: '', isUnlisted: false }}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .required('Group name is required.')
          .max(50, 'Name cannot be more than 50 characters.'),
        description: yup
          .string()
          .max(160, 'Description cannot be more than 160 characters.'),
        isUnlisted: yup.boolean().required('Required'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true)
        const { user, history } = props
        const db = firebase.firestore()
        const members = {}
        members[user.userId] = true

        db.collection('groups')
          .add({
            ...values,
            groupAdmin: user.userId,
            entries: [],
            isFeatured: false,
            members,
          })
          .then(function(docRef) {
            setSubmitting(false)

            history.push(`/groups/${docRef.id}`)
          })
          .catch(function(error) {
            console.error('Error adding document: ', error)
          })
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        // isSubmitting,
        setFieldValue,
        isValid,
      }) => {
        const { name, description, isUnlisted } = values
        const nameError = touched.name && errors.name
        const descriptionError = touched.description && errors.description
        // const isUnlistedError = touched.isUnlisted && errors.isUnlisted

        return (
          <StyledForm onSubmit={handleSubmit}>
            <TextInput
              placeholder="Enter group name"
              value={name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={nameError}
              name="name"
            />
            <TextInput
              placeholder="Describe your group and name your stakes"
              value={description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={descriptionError}
              name="description"
            />
            <Switch
              leftText="Public"
              rightText="Unlisted"
              onChange={() => setFieldValue('isUnlisted', !isUnlisted)}
              checked={isUnlisted}
              style={{ margin: '1.5rem 0 2rem' }}
            />
            <Button
              type="submit"
              style={{ alignSelf: 'center' }}
              disabled={!isValid}
            >
              Submit
            </Button>
          </StyledForm>
        )
      }}
    </Formik>
  )
}

export const CreateGroupPage = props => {
  return (
    <PageWrapper tiled>
      <h1>Create Group</h1>
      <p>
        Compete against your group in the game of thrones. Fill out your death
        pool, then invite your friends!
      </p>
      <PowerImage src={KingsLanding} alt="Kings landing" />
      <CreateGroupForm {...props} />
    </PageWrapper>
  )
}
