import React from 'react'
import { withRouter } from 'react-router-dom'

const IsFromProductHuntContext = React.createContext()

class IsFromProductHuntComponent extends React.Component {
  state = {
    isFromProductHunt: false,
  }

  componentDidMount() {
    const currentUrl = window && window.location && window.location.href
    if (currentUrl) {
      this.setState({
        isFromProductHunt: currentUrl.includes('?ref=producthunt'),
      })
    }
  }

  render() {
    const { isFromProductHunt } = this.state
    const { children } = this.props

    return (
      <IsFromProductHuntContext.Provider
        value={{
          isFromProductHunt,
        }}
      >
        {children}
      </IsFromProductHuntContext.Provider>
    )
  }
}

export const IsFromProductHuntProvider = withRouter(IsFromProductHuntComponent)

export const IsFromProductHuntConsumer = IsFromProductHuntContext.Consumer
