import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from '../context'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <AuthConsumer>
    {({ isSignedIn, user }) => (
      <Route
        render={props =>
          isSignedIn ? (
            <Component {...props} user={user} />
          ) : (
            <Redirect
              to={{
                pathname: '/sign-up',
                state: { from: props.location },
              }}
            />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
)
