import React from 'react'
import firebase from '../config/firebase'
import { withRouter } from 'react-router-dom'

const AuthContext = React.createContext()

class AuthProviderComponent extends React.Component {
  state = { isSignedIn: false, user: null, authChecked: false }

  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async userAuth => {
        if (userAuth) {
          const db = firebase.firestore()
          this.unregisterFromUserListener = await db
            .collection('users')
            .doc(userAuth.uid)
            .onSnapshot(doc => {
              const userInfo = doc.data()
              console.log('USER =>', userInfo)

              this.setState({
                isSignedIn: true,
                user: userInfo,
                authChecked: true,
              })
            })
        } else {
          this.setState({
            isSignedIn: !!userAuth,
            user: userAuth,
            authChecked: true,
          })
        }
      })
  }

  componentWillUnmount() {
    this.unregisterFromUserListener()
    this.unregisterAuthObserver()
  }

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          // Send home on logout
          const { history } = this.props
          history.push('/')
          this.setState({ isSignedIn: false, user: null })
        },
        error => {
          console.error(error)
          // An error happened.
        }
      )
  }

  render() {
    const { isSignedIn, user, authChecked } = this.state
    const { children } = this.props
    return (
      <AuthContext.Provider
        value={{
          isSignedIn,
          user,
          logout: this.logout,
          authChecked,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

export const AuthProvider = withRouter(AuthProviderComponent)

export const AuthConsumer = AuthContext.Consumer
