import React from 'react'
import { PageWrapper } from '../components'
import { Link } from 'react-router-dom'
import { LOCK_MODE } from '../utils/const'

export const NotFoundPage = () => {
  return (
    <PageWrapper tiled>
      <h1>Not found!</h1>
      <p>
        We couldn't find what you were looking for. This could be because the
        group admin deleted the group.
      </p>
      {!LOCK_MODE && (
        <p>
          Feel free to create a <Link to="/create-group">new group</Link> and
          create an entry!
        </p>
      )}
    </PageWrapper>
  )
}
