import { css } from 'styled-components'

const sizes = {
  forSmallOnly: {
    max: true,
    width: 680,
  },
  forSmallMediumOnly: {
    max: true,
    width: 991,
  },
  forMediumUp: {
    max: false,
    width: 681,
  },
  forLargeUp: {
    max: false,
    width: 992,
  },
}

// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (sizes[label]['max']) {
      return css`
        @media (max-width: ${sizes[label]['width'] / 16}em) {
          ${css(...args)};
        }
      `
    } else {
      return css`
        @media (min-width: ${sizes[label]['width'] / 16}em) {
          ${css(...args)};
        }
      `
    }
  }

  return acc
}, {})

export const theme = {
  defaultHeaderColor: '#333333',
  disabledColor: '#888888',
  grayText: '#959595',
  desktopHeaderHeight: '80px',
  focusColor: '#4c9aff',
  linkColor: '#2f74ce',
  errorColor: '#e44100',
  negativeColor: '#cc432a',
  positiveColor: '#52b74d',
  defaultInputBackgroundColor: '#f5f5f5',
  mobileHeaderHeight: '60px',
  defaultFontColor: '#090728',
  primaryColor: '#010628',
  defaultFont: 'Muli, Roboto, "Helvetica Neue", Arial, "Noto Sans"',
  white: '#ffffff',
  defaultHeaderFontColor: '#090728',
  defaultTransition: 'all 0.2s linear',
  defaultFontSize: '18px',
  defaultMobileFontSize: '16px',
  tableHeaderStyles: css`
    height: 70px;

    ${media.forSmallOnly`
      height: 60px;
    `}
  `,
}
