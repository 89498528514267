import React from 'react'
import { Nav, Loading, PrivateRoute, Footer } from './components'
import {
  HomePage,
  SignUpPage,
  HowItWorksPage,
  HomeAuthedPage,
  CreateGroupPage,
  NotFoundPage,
  GroupDetailPage,
  // CreateEntryPage,
  ViewEntryPage,
  PrivacyPolicyPage,
  TermsPage,
  EditEntryPage,
  AnalyticsPage,
  DonatePage,
} from './pages'
import { Route, Switch } from 'react-router-dom'
import { GlobalStyle } from './style'
import { AuthConsumer } from './context'
import * as Sentry from '@sentry/browser'
import { EntriesProvider } from './context'
// import { PageWrapper, FancyGraphic } from './components'
// import { ReactComponent } from './assets/throneLogo.svg'
import { LOCK_MODE } from './utils/const'

// const UnderConstruction = () => {
//   return (
//     <PageWrapper
//       tiled
//       containerStyle={{
//         minHeight: '100vh',
//       }}
//       tileStyle={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <ReactComponent style={{ width: '100%' }} />
//       <h1 style={{ margin: '1rem 0', textAlign: 'center' }}>
//         Winter is here and entries have locked.
//       </h1>
//       <FancyGraphic />
//       <p style={{ textAlign: 'center' }}>
//         We will be delaying the scoring to avoid spoilers for people in
//         different timezones. Check back soon for a new experience and scores!
//       </p>
//     </PageWrapper>
//   )
// }

const AppComponent = ({ authChecked, isSignedIn, user }) => {
  if (!authChecked) {
    return <Loading />
  }

  return (
    <EntriesProvider user={user}>
      <>
        <GlobalStyle />
        <Nav />
        <Switch>
          {isSignedIn ? (
            <PrivateRoute path="/" exact component={HomeAuthedPage} />
          ) : (
            <Route path="/" exact component={HomePage} />
          )}

          <Route path="/sign-up" exact component={SignUpPage} />
          <Route path="/faq" exact component={HowItWorksPage} />
          <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />
          <Route path="/terms-and-conditions" exact component={TermsPage} />
          <Route path="/analytics" exact component={AnalyticsPage} />
          <Route path="/donate" exact component={DonatePage} />
          {!LOCK_MODE && (
            <PrivateRoute
              path="/create-group"
              exact
              component={CreateGroupPage}
            />
          )}
          <PrivateRoute
            path="/groups/:groupId"
            exact
            component={GroupDetailPage}
          />

          <PrivateRoute
            path="/groups/:groupId/create-entry"
            exact
            component={NotFoundPage}
          />

          <PrivateRoute
            path="/groups/:groupId/:entryId"
            exact
            component={ViewEntryPage}
          />
          {!LOCK_MODE && (
            <PrivateRoute
              path="/groups/:groupId/:entryId/edit"
              exact
              component={EditEntryPage}
            />
          )}
          <Route component={NotFoundPage} />
        </Switch>
        <Footer />
      </>
    </EntriesProvider>
  )
}

class App extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    return (
      <AuthConsumer>
        {authProps => <AppComponent {...authProps} />}
      </AuthConsumer>
    )
  }
}

export default App
