import React from 'react'
import { PageWrapper, Loading } from '../components'
import firebase from '../config/firebase'
import { CreateEntryForm } from './CreateEntry'

const db = firebase.firestore()

export class EditEntryPage extends React.Component {
  state = { entryDetail: null }
  async componentDidMount() {
    const {
      match: { params },
    } = this.props

    const entryDetailReq = await db
      .collection('entries')
      .doc(params.entryId)
      .get()
    const entryDetail = entryDetailReq.data()
    entryDetail.id = entryDetailReq.id

    this.setState({ entryDetail })
  }

  render() {
    const { entryDetail } = this.state
    const { props } = this

    if (!entryDetail || !props.user) {
      return (
        <PageWrapper>
          <Loading />
        </PageWrapper>
      )
    }

    return (
      <PageWrapper>
        <CreateEntryForm entryDetail={entryDetail} {...props} />
      </PageWrapper>
    )
  }
}
